<template>
    <!-- 签到 -->
    <div class="sign" v-if="sign_template">
        <img class="sign-bg" src="~assets/images/task/sign-bg.png"/>
        <!-- 签到开始 -->
        <div class="sign-wiper">
            <div class="sign-in-daily">
                <div class="sign-in-daily-con vertical-center">
                    <div class="now-experience vertical-center">
                        <div class="now-experience-title">目前经验值</div>
                        <div class="now-experience-num">{{ $handle.tranNumber(userInfo.experience,3) }}</div>
                        <div class="now-experience-tip">已连续签到{{ receptions.totalReception_day }}天，继续加油！</div>
                    </div>
                    <!--签到主体开始-->
                    <div class="sign-con">
<!--                        <div class="sign-title">2020-03</div>-->
                        <div class="sign-body flex-wrap">
                            <van-grid column-num="7" :border="false" v-for="(item,index) in signData" :key="index">
                                <van-grid-item use-slot>
                                    <div class="sign-item vertical-center">
                                        <div class="sign-img-con">
                                            <img :class="[item.dayExperience  ? 'sign-img' :'sign-reward']"  v-if="item.is_sign"
                                                   :src="[item.dayExperience ? require('../../../assets/images/user/signed-icon.png') : require('../../../assets/images/user/sign-reward-icon2.png')]"/>
                                            <img :class="[item.dayExperience  ? 'sign-img' :'sign-reward']" v-else
                                                   :src="[item.dayExperience ? require('../../../assets/images/user/not-signed-icon.png' ): require('../../../assets/images/user/sign-reward-icon1.png') ]"/>
                                            <div class="sign-img-text " :class="[ item.is_sign ? 'signed-img-text' :'']">{{ $handle.tranNumber(item.experience,3) }}</div>
                                        </div>
                                        <div class="sign-text">{{ item.is_sign ? '已签' : (index+1)+'天'}}</div>
                                    </div>
                                </van-grid-item>
                            </van-grid>

                        </div>
                    </div>
                    <!--签到主体结束-->
                </div>
                <div class="sign-tip align-center">
                    <div class="immediately" :class="receptions.is_sign ? 'immediately-active' : ''" @click="signImmediately(receptions.data.length,receptions.dayExperience,receptions.rule_id)">{{receptions.is_sign ? '已签到' : '立即签到'}}</div>
                    <div class="sign-tip-con vertical-center">
                        <img class="sign-tip-img" src="~assets/images/task/sing-tip.png"/>
                        <div class="sign-tip-title">完成须知：每日最多可签到一次，断签则会重新计算连签天数</div>
                    </div>
                </div>
            </div>
            <!-- 签到奖励开始 -->
            <div class="sign-in-reward">
                <div class="reward-title">签到奖励</div>
                <div class="reward-con">
                    <ul class="reward-wiper">
                        <li class="space-between">
                            <div class="reward-item" :class="{blue: index === 1 ? 'blue' : '', pink: index === 2 ? 'pink' : ''}" v-for="(item,index) in receptions.continue" :key="index" v-show="index<3">
                                <img class="reward-img" src="~assets/images/user/sign-bg.png"/>
                                <div class="sngn-reward">
                                    <div class="reward-tltle">连续签到{{ item.days }}天</div>
                                    <div class="reward-tip">{{ $handle.tranNumber(item.experience,3) }}经验值</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 签到奖励结束 -->
        </div>
        <!-- 签到结束 -->

        <!--签到成功提示开始-->
        <van-overlay :show="showOverlay" z-index="9999">
            <div class="overlay-successful-sign-con">
                <div class="overlay-successful-sign">
                    <div class="overlay-successful-head">
                        <img class="overlay-successful-img" src="~assets/images/user/overlay-sign-success.png"/>
                        <img class="overlay-successful-title-img" src="~assets/images/user/overlay-cancel.png" @click="closeOverlay"/>
                    </div>
                    <div class="overlay-successful-body">
                        <div class="overlay-successful-text">签到成功</div>
                        <div class="overlay-btn" @click="closeOverlay">
                            <div class="confirm">好的</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--签到成功提示结束-->
        <van-toast id="van-toast" />
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    name: "sign",
    data(){
        return {
            receptions: {},
            signData: [],       //签到信息
            showOverlay: false,
            singDay: 0,
            sign_template: false,
            sign_status: true
        }
    },

    created() {
        this.getReceptions()
        this.VisitsFun()
    },
    methods:{
        //获取用户签到信息
        getReceptions(){
            let _this = this
            _this.sign_status = true;
          let url = _this.$api.Receptions
            _this.$http.get(url,true).then(res => {
                if (res.data.success){
                    _this.receptions = res.data.data
                    let info = res.data.data;
                     let singinArr = [];
                    // 默认初始数据
                    for (let i = 0;i< info.cycle; i++){
                        if(i <info.cycle){
                            singinArr.push({
                                days: i,
                                experience: info.dayExperience,
                                dayExperience:true,
                                is_sign: false
                            })
                        }else {
                            singinArr.push({
                                days: i,
                                dayExperience: info.dayExperience,
                                is_sign: false
                            })
                        }
                    }
                    for (let i = 0;i < singinArr.length;i++){
                        for (let j = 0;j < info.continue.length;j++){
                            if(info.continue[j].days == (i+1)){
                                singinArr[i] = {
                                    days: i,
                                    experience: info.continue[j].experience,
                                    dayExperience:false
                                }
                            }
                        }
                    }

                  if(info.totalReception_day == info.cycle) {
                    if(!info.is_sign) {
                      singinArr[info.totalReception_day -1].is_sign = false
                    }else{
                      for (let i=0; i< info.totalReception_day; i++){
                        if(singinArr[i]) {
                          singinArr[i].is_sign = true
                        }
                      }
                    }
                  }else if(info.totalReception_day > info.cycle) {
                    let his = info.totalReception_day % info.cycle
                    for (let i=0; i< his; i++){
                      singinArr[i].is_sign = true
                    }
                    if(his == 0) {
                      if(!info.is_sign) {
                        singinArr[info.cycle -1].is_sign = false
                      }else {
                        for (let i=0; i< info.totalReception_day; i++){
                          if(singinArr[i]) {
                            singinArr[i].is_sign = true
                          }
                        }
                      }
                    }
                  }else if(info.totalReception_day < info.cycle){
                    for (let i=0; i< info.totalReception_day; i++){
                      if(singinArr[i]){
                        singinArr[i].is_sign = true
                      }
                    }
                  }

                    _this.signData = singinArr
                    _this.sign_template = true;
                    if (_this.receptions.data.length == 0){
                        return
                    }
                    if (_this.receptions.continue.length > 3) {
                        // const singDay = _this.receptions.data[_this.receptions.data.length - 1].day
                        const singDay = _this.receptions.data.length
                        let singDay_difference =  0
                        for (let i = 0; i< _this.receptions.continue.length; i ++){
                            if (singDay === _this.receptions.cycle) break
                            if (_this.receptions.continue[i].days < singDay){
                                singDay_difference ++
                            }
                        }
                        for (let i = 0; i< singDay_difference; i ++){
                            _this.receptions.continue.shift()
                            if (_this.receptions.continue.length == 3){
                                break
                            }
                        }
                    }

                }
            })
        },
        // 点击立即签到
        signImmediately: _.debounce(function (reception_day,experience,rule_id){
          let _this = this
          let url = _this.$api.Receptions
          if (this.receptions.is_sign) {
            return false
          }
          if(!this.sign_status){
            return false
          }
          this.sign_status = false;
          let data = {
            reception_day: _this.receptions.totalReception_day +1,
            rule_id
          }
          // if ((reception_day + 1) > _this.receptions.cycle) {
          //   let current_data = (reception_day +1) % _this.receptions.cycle
          //   data.experience = this.signData[current_data -1].experience
          //   if(current_data == 0) {
          //     data.reception_day = 1
          //   }else {
          //     data.reception_day = current_data
          //   }
          // }else {
          //   data.experience = this.signData[reception_day].experience
          // }
          if ((_this.receptions.totalReception_day + 1) > _this.receptions.cycle) {
            let current_data = (_this.receptions.totalReception_day +1) % _this.receptions.cycle
            if(current_data == 0) {
              data.reception_day = _this.receptions.cycle
            }else{
              data.reception_day = current_data
            }
          }
          _this.$http.post(url,data,true).then(res => {
            if (res.data.success){
              _this.showOverlay = true
            }else {
              _this.sign_status = true;
              _this.$toast(res.data.message)
              _this.getReceptions()
            }
          })
        },500),
        //点击弹出框
        closeOverlay(){
            this.showOverlay = false
            this.$user.getUserInfo();
            this.getReceptions()
        },
        // 记录用户访问页面
        VisitsFun() {
          let url = this.$api.Visits
          this.$http.post(url, {view: 'sign',source: 'mp'}, true)
        }
    },
    watch:{
        userInfo(val, oldVal){
            return val;
        }
    },
    computed:{
        userInfo(){
            return this.$store.getters.userInfo;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/sign/sign.scss";
</style>
